































import { Veiculo } from '@/core/models/geral';
import { EmpresaService, ImportacaoService } from '@/core/services/geral';
import { PageBase } from '@/core/services/shared';
import { Component, Watch } from 'vue-property-decorator';

@Component
export default class Importacao extends PageBase {
  
  overlay: boolean = false;
  valid = true;
  empresaId: number = 0;
  arquivo: any = null;
  model: any;
  service: ImportacaoService = new ImportacaoService();
  empresaService: EmpresaService = new EmpresaService();
  empresas: [] = [];
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  $refs!: {
    form: HTMLFormElement
  }

  mounted(){
    this.empresaService.ListarTudo().then(
      res => {
        this.empresas = res.data.items;
      },
      err => this.$swal('Aviso', err.response.data, 'warning')
    )
  }

  Enviar(){

    if (this.$refs.form.validate()) {

      this.overlay = true;
      let vm = this;

      let promise1 = new Promise(function(resolve){

        if (!vm.arquivo) {
          resolve(null);
        }

        let reader = new FileReader();
        reader.readAsText(vm.arquivo, 'ISO-8859-1');
        reader.onloadend = function(event) {
          let csv = event!.target!.result;
          vm.model = vm.TableToJSON(csv);
          resolve(vm.model);
        };

      });

      Promise.all([promise1]).then(function() {
        
        vm.model.forEach(x => {
          if(x.montadora){
            x.marca = x.montadora;
            delete x.montadora;
          }
          if(x[ 'valor fipe veiculo']){
            x.fipe = x[ 'valor fipe veiculo'];
            delete x[ 'valor fipe veiculo'];
            x.fipe = parseFloat((x.fipe.split("R$ ")[1]).replace('.','').replace(",", "."));
          }
        })

        vm.service.Importar(vm.model)
        .then(
          res => {
            vm.overlay = false;
            vm.model = new Veiculo();
            vm.$swal('Aviso', 'Importação realizada com sucesso!', res.status == 201 || res.status == 200 ? 'success' : 'warning');
          }, 
          err =>  {
            vm.overlay = false;
            vm.$swal('Aviso', err.response.data, 'warning');
          }
        );
      });
    }

  }

  TableToJSON(html){

    let parser = new DOMParser();
    let doc    = parser.parseFromString(html, "text/html");

    let header: any[] = [].map.call(doc.querySelectorAll('tr > th'), (th: any) => {
      return th.textContent.toLowerCase();
    });

    let list = [].map.call(doc.querySelectorAll('tr'), (tr: any) => {
        return [].slice.call(tr.querySelectorAll('td')).reduce( (a,b: any,i) => {
          
          return a[header[i]] = b.textContent, a;

        }, {});
    });

    list.splice(0, 1);

    let filtered = list.filter((value: any, index, arr) => {
        value.empresaId = this.empresaId;
        return value.placa.length == 7 || !value.placa;
    });

    return filtered;;

  }

  csvJSON(csv){
      let lines = csv.split("\r\n");
      let result: any[] = [];
      let headers = lines[0].split(";");
      headers = headers.map(Function.prototype.call, String.prototype.trim);

      let vm = this;
      
      lines.map(function(line, indexLine){
          if (indexLine < 1) return;
          
          let obj : any = {};
          let currentline = line.split(";");
          currentline = currentline.map(Function.prototype.call, String.prototype.trim);

          headers.map(function(header, indexHeader){
            obj[header] = currentline[indexHeader];              
          })
          
          result.push(obj);
      })

      result.pop();
      
      return result;
  }

}
